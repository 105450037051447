import React, {useEffect, useState} from 'react';
import {activate_account, clear_user_module, ACTIVATE_ACCOUNT} from '../../redux/users';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory, useParams} from "react-router-dom";
import InputPassword from '../../components/input_password';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../../components/loading-spinner";

const ActivateAccount = () => {
    const {user_id, token} = useParams()
    const _activate_account = useSelector((state) => state.users_module._activate_account);
    let history = useHistory();

    const dispatch = useDispatch();
    const [activate, setActivate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [form, setForm] = useState({
        new_password: '',
        confirmation_token: token,
        user_id: user_id
    });

    useEffect(() => {
        if (_activate_account.status === 200) {
            dispatch(clear_user_module(ACTIVATE_ACCOUNT));
            setActivate(true);
            setIsLoading(false);
        } else if (_activate_account.status !== 0) {
            toast.error(_activate_account.message, {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_user_module(ACTIVATE_ACCOUNT));
            setIsLoading(false);
        }
    }, [_activate_account, dispatch, history]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.new_password === '') {
            toast.error("Debe ingresar la contraseña", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        } else {
            if (form.new_password.length < 8 || form.new_password.length > 20) {
                toast.error("La contraseña debe tener un mínimo de 8 caracteres y un máximo de 20 caracteres.", {position: toast.POSITION.TOP_RIGHT});
                setIsLoading(false);
                return;
            } else {
                const mayymin = /(?:[A-Z])/.test(form.new_password) && /(?:[a-z])/.test(form.new_password);
                const numeros = /(?:\d)/.test(form.new_password);
                const especial = /(?=.*?[#?!@$ %^&*-_])/.test(form.new_password);
                const valida = mayymin && numeros && especial;
                if (!valida) {
                    toast.error("La contraseña debe incluir al menos un número, una letra mayúscula y un carácter especial.", {position: toast.POSITION.TOP_RIGHT});
                    setIsLoading(false);
                    return;
                }
            }
        }
        dispatch(activate_account(form));
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    return (
        <>
            <div className="breadcrumbs_area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_content">
                                <h3>Activa tu cuenta</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="customer_login">
                <div><ToastContainer/></div>
                {isLoading && <LoadingSpinner/>}
                <div className="container">
                    {!activate ?
                        <div className="row">
                            <div className="col-md-6 col-12 text-center">
                                <img style={{width: '50%'}}
                                     src={window.location.protocol + "//" + window.location.host + "/assets/img/logo/lock-screen.png"}
                                     alt="branding logo"/>
                            </div>
                            <div className="col-md-6 col-12" style={{alignContent: 'center'}}>
                                <form className="mb-2" onSubmit={handleSubmit}>
                                    <InputPassword
                                        type="password"
                                        name="new_password"
                                        id="new_password1"
                                        id_div1="show_hide_password_new1"
                                        id_div2="password_new1"
                                        onChange={handleChange}
                                        label="Nueva contraseña"
                                        placeholder="Nueva contraseña"
                                        required={true}
                                    />
                                    <div className='mt-1 mb-1 p-1' style={{background: 'rgba(251,221,188,0.65)'}}>
                                        <small>* La contraseña debe tener un mínimo de 8 caracteres y un máximo de 20
                                            caracteres.</small><br/>
                                        <small>* La contraseña debe incluir al menos un número, una letra mayúscula y un
                                            carácter especial.</small><br/>
                                        <small>* La contraseña no puede estar relacionada con el usuario.</small>
                                    </div>
                                    <button type="submit" className={`btn btn-calendar-cliente1 mt-3 button_div`} disabled={isLoading}>
                                        Activar mi cuenta
                                    </button>
                                </form>
                            </div>
                        </div>
                        :
                        <div className="text-center">
                            <img
                                style={{width: '50%'}}
                                src={window.location.protocol + "//" + window.location.host + "/assets/img/logo/lock-screen.png"}
                                alt="branding logo"
                            />

                            <Link to="/" className={`btn btn-calendar-cliente1 mt-3 button_div`}>
                                Iniciar sesión
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ActivateAccount;
