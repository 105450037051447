import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import LoadingSpinner from "../../components/loading-spinner";
import 'react-toastify/dist/ReactToastify.css';
import {URLAPI, ORDER_DOWNLOAD_PDF_PATH} from "../../config";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../../components/modal";
import Input from "../../components/input";
import {get_all_order, clear_status_order, payment_order, PAYMENT_ORDER_SUCCESS} from "../../redux/order";
import {theme} from "../../config";
import Whatsapp from "../../components/whatsapp";

const ItemOrder = (props) => {
    const history = useHistory();
    const {id} = useParams();
    const dispatch = useDispatch();
    const _payment_order = useSelector((state) => state.order_module._payment_order);
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState({
        fecha_creacion: "",
        codigo_interno: "",
        estado: "",
        cotizacion: {
            detalles: [],
            valor_anticipo: 0,
            costo: 0,
            total: 0,
        }
    });

    const [config, setConfig] = useState({
        moneda: '',
        cantidad_decimal: '',
        separador_decimal: '',
    });

    const _get_all_order = useSelector((state) => state.order_module._get_all_order);
    //const _delete_item_shopping_cart = useSelector((state) => state.shopping_cart_module._delete_item_shopping_cart);


    useEffect(() => {
        if (_get_all_order.data && _get_all_order.data.datos) {
            let value = _get_all_order.data.datos.filter(x => parseInt(x.id) === parseInt(id));
            if (value.length > 0) {
                setData(value[0]);
            }
            setConfig(_get_all_order.data.configuraciones);
        }
    }, [_get_all_order, id]);

    const [modalPayment, setModalPayment] = useState(false);
    const [file, setFile] = useState('');
    const [item, setItem] = useState([]);

    useEffect(() => {
        if (_payment_order.status === 200) {
            dispatch(clear_status_order(PAYMENT_ORDER_SUCCESS));
            setIsLoading(false);
            dispatch(get_all_order(token, 1));
            setModalPayment(false);
            history.push(`/mis-fabricaciones`);
        } else if (_payment_order.status !== 0) {
            dispatch(clear_status_order(PAYMENT_ORDER_SUCCESS));
            toast.error(_payment_order.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_payment_order, dispatch, token, history, id]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        //validate fields
        if (file === "") {
            toast.error("Debe seleccionar un comprobante", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        let datas = new FormData();
        datas.append(`comprobante`, file);
        setIsLoading(true);
        dispatch(payment_order(token, id, datas))

    }

    return (
        <div className="product_details mt-5">
            <div><ToastContainer/></div>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <h2 className="text-titulo">{data.codigo}</h2>
                    </div>
                    <div className="col-6 text-right">
                        <span style={{color: theme(data.estado.toUpperCase())}}>{data.estado.toUpperCase()}</span>
                    </div>
                    <div className="col-12 mt-2">
                        <strong>Fecha de creación: </strong><span>{data.fecha_creacion}</span>
                    </div>
                    {(data) &&
                        <>
                            {(data.estado === 'Pendiente inicio') && (
                                <div className="col-12">
                                    <div className="banner_text mb-3">
                                        <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Vamos a
                                            iniciar el proceso de
                                            fabricación de tus joyas, te informaremos por correo electrónico cuando
                                            estén listas
                                            para retirar.</h4>
                                    </div>
                                </div>
                            )}
                            {(data.estado === 'En proceso') && (
                                <div className="col-12">
                                    <div className="banner_text mb-3">
                                        <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Estamos
                                            en proceso de fabricación de
                                            tus joyas, te informaremos por correo electrónico cuando estén listas para
                                            retirar.</h4>
                                    </div>
                                </div>
                            )}
                            {(data.estado === 'Fabricado') && (
                                <div className="col-12">
                                    <div className="banner_text mb-3">
                                        <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Hemos
                                            terminado la fabricación de tus
                                            joyas, por favor debes realizar el pago del saldo pendiente y cargar el
                                            comprobante
                                            en esta página.</h4>
                                    </div>
                                </div>
                            )}
                            {(data.estado === 'Finalizada') && (
                                <div className="col-12">
                                    <div className="banner_text mb-3">
                                        <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Hemos
                                            validado tu pago, tu fabricación
                                            está lista para el envío.</h4>
                                    </div>
                                </div>
                            )}
                            <div className="col-12 mt-3">
                                <div className="table_desc">
                                    <div className="cart_page table-responsive">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th className="product_thumb">Imagen</th>
                                                <th className="product_name">Código interno</th>
                                                <th className="product_name">Producto</th>
                                                <th className="product_quantity">Cantidad</th>
                                                <th className="product_quantity">Precio</th>
                                                <th className="product_quantity">Subtotal</th>
                                                <th className="product_name">Observación</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.cotizacion.detalles.map((item) => (
                                                <tr data-bs-toggle="modal" data-bs-target="#modal_box"
                                                    style={{cursor: 'pointer'}} onClick={() => {
                                                    setItem(item);
                                                }}>
                                                    <td className="product_thumb"><img
                                                        src={URLAPI + item.imagenes[0].url} alt=""
                                                        style={{width: '30%'}}/></td>
                                                    <td className="product_name">{item.codigo_interno}</td>
                                                    <td className="product_name">{item.titulo}</td>
                                                    <td className="product_quantity">{item.cantidad}</td>
                                                    <td className="product-price">{config.moneda} {parseFloat(item.precio).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</td>
                                                    <td className="product_total">{config.moneda} {parseFloat(item.subtotal).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</td>
                                                    <td className="product_name">{item.observacion}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="coupon_area">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="coupon_code right">
                                            <h3>Totales</h3>
                                            <div className="coupon_inner">
                                                {/*  <div className="cart_subtotal">
                                                    <p>Anticipo de fabricación</p>
                                                    <p className="cart_amount">{config.moneda} {parseFloat(data.cotizacion.valor_anticipo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</p>
                                                </div>
                                                <small>Corresponde
                                                    al {config.porcentaje_anticipo}% del total de la fabricación, depósito o
                                                    transferencia al confirmar la fabricación</small> */}
                                                <div className="cart_subtotal ">
                                                    <p>Envio</p>
                                                    <p className="cart_amount">{config.moneda} {parseFloat(data.cotizacion.costo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</p>
                                                </div>

                                                <div className="cart_subtotal">
                                                    <p>Total</p>
                                                    <p className="cart_amount">{config.moneda} {parseFloat(data.cotizacion.total).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</p>
                                                </div>
                                                <div className="checkout_btn">
                                                    {/* eslint-disable-next-line */}
                                                    <a href={`${URLAPI}${ORDER_DOWNLOAD_PDF_PATH}${id}/`}
                                                       target="_blank"
                                                       className="btn btn-calendar-cliente1" style={{margin: 1}}>Descargar
                                                        orden de
                                                        fabricación</a>
                                                    <button style={{marginRight: 5}} onClick={() => {
                                                        history.push(`/mis-fabricaciones`);
                                                    }}>Atras
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div>
            </div>
            <Modal className="modal-main" show={modalPayment}>
                {isLoading && <LoadingSpinner/>}
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Cargar comprobante</h5>
                        <Input
                            name="file"
                            label="Archivo"
                            type="file"
                            onChange={handleFileChange}
                            color_label="text-color"
                        />
                    </div>
                    <div className="text-center div_footer">
                        <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}
                                disabled={isLoading}>Cargar
                        </button>
                        <button type="button" onClick={() => {
                            setModalPayment(false);
                        }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <div className="modal fade" id="modal_box" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal_body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        {item &&
                                            <div id="custCarousel" className="carousel slide border-carousel"
                                                 data-ride="carousel" align="center">
                                                <div className="carousel-inner">
                                                    {item.imagenes ?
                                                        item.imagenes.map((x, index) => (
                                                            <div
                                                                className={(index === 0 ? "carousel-item active" : "carousel-item")}>
                                                                <img src={URLAPI + x.url} alt="Hills"/>
                                                            </div>
                                                        ))
                                                        :
                                                        <div className="carousel-item active">
                                                            <img id="zoom1"
                                                                 src="../../../../assets/img/logo/no-image.png"
                                                                 data-zoom-image="../../../../assets/img/logo/no-image.png"
                                                                 alt="Hills"/>
                                                        </div>
                                                    }
                                                </div>
                                                {/* eslint-disable-next-line */}
                                                <a className="carousel-control-prev" href="#custCarousel"
                                                   data-slide="prev">
                                                    <span className="carousel-control-prev-icon"></span>
                                                </a>
                                                {/* eslint-disable-next-line */}
                                                <a className="carousel-control-next" href="#custCarousel"
                                                   data-slide="next">
                                                    <span className="carousel-control-next-icon"></span>
                                                </a>

                                                <ol className="carousel-indicators list-inline">
                                                    {item.imagenes &&
                                                        item.imagenes.map((x, index) => (
                                                            <li className={(index === 0 ? "list-inline-item active" : "list-inline-item")}>
                                                                {/* eslint-disable-next-line */}
                                                                <a id={'carousel-selector-' + index}
                                                                   className={(index === 0 ? "list-inline-item active" : "list-inline-item")}
                                                                   data-slide-to={index} data-target="#custCarousel">
                                                                    {/* eslint-disable-next-line */}
                                                                    <img src={URLAPI + x.url} className="img-fluid"/>
                                                                </a>

                                                            </li>
                                                        ))
                                                    }
                                                </ol>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12">
                                        <div className="modal_right">
                                            <div className="modal_title mb-10">
                                                <small>{item.codigo_interno}</small>
                                                <h2>{item.titulo}</h2>
                                            </div>
                                            <div className="modal_price mb-10">
                                                <span
                                                    className="new_price">{config.moneda} {parseFloat(item.subtotal).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</span>
                                            </div>
                                            <div className="modal_description mb-15">
                                                <p>{item.descripcion}</p>
                                            </div>
                                            <div className="modal_social row">
                                                <h2>Detalles</h2>
                                                {item.valores &&
                                                    item.valores.map(a => (
                                                        <div className="col-4 mb-2">
                                                            <small>{a.atributo}</small><br/>
                                                            <strong>{a.valor}</strong>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Whatsapp/>
        </div>
    );
};

export default ItemOrder;
