import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {URLAPI} from '../config/index'

const Navbar = () => {
    const history = useHistory();
    
    const _get_all_family = useSelector(
        (state) => state.family_module._get_all_family
    );

    const [families, setFamilies] = useState([]);

    useEffect(() => {
        if (_get_all_family && _get_all_family.data && _get_all_family.data.datos) {
            let values = _get_all_family.data.datos.filter((x) => x.activo === true);
            setFamilies(values);
        }
        // eslint-disable-next-line
    }, [_get_all_family]);

    return (
        <footer className="footer_widgets" style={{backgroundColor: '#033a2a', paddingTop: 20, backgroundImage: "url(/assets/img/logo/vicenza.png)",}}>
            <div className="container">
                <div className="footer_top">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-8">
                            <div className="widgets_container contact_us">
                                <h3 className="text-titulo">Contáctenos</h3>
                                <div className="footer_contact">
                                    <p className="text-color">Llámanos:
                                        {/* eslint-disable-next-line */}
                                        <a>+57 317 665 4051</a>
                                    </p>
                                    <p className="text-color">Contacto: dir.administrativo@vicenzajoyeros.com</p>
                                    <ul>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                        </li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#"><i className="fa fa-twitter"></i></a>
                                        </li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#"><i className="ion-social-rss"></i></a>
                                        </li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#"><i className="ion-social-googleplus"></i></a>
                                        </li>

                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#"><i className="ion-social-youtube"></i></a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="widgets_container widget_menu">
                                <h3 className="text-titulo">Información</h3>
                                <div className="footer_menu">
                                    <ul>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#" className="text-color">Quienes somos</a>
                                        </li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#" className="text-color">Que hacemos</a>
                                        </li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#" className="text-color">Servicio al cliente</a>
                                        </li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#" className="text-color">Preguntas frecuentes</a>
                                        </li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#" className="text-color">Despacho</a></li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#" className="text-color">Políticas de seguridad</a>
                                        </li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#" className="text-color">Términos y condiciones</a>
                                        </li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#" className="text-color">Contacto</a>
                                        </li>
                                        <li>
                                            {/* eslint-disable-next-line */}
                                            <a href="#" className="text-color">Denuncias</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="widgets_container product_widget">
                                <h3 className="text-titulo">Familias</h3>
                                <div className="simple_product">
                                    {families.map((item) => (
                                        <div className="simple_product_items" onClick={() => {history.push(`/familia/familia/${item.nombre}`);}} style={{cursor: 'pointer'}}>
                                            <div className="simple_product_thumb">
                                                <img
                                                    src={URLAPI + item.imagen}
                                                    alt="logo"
                                                    style={{width: '25%'}}
                                                />
                                            </div>
                                            <div className="simple_product_content">
                                                <div className="product_name">
                                                    <h3 className="text-color">{item.nombre}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer_bottom">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright_area">
                                <p className="text-color">{new Date().getFullYear()} © INNODE</p>
                                <img src="assets/img/icon/papyel2.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>        
    );
};

export default Navbar;
